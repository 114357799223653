exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-compare-candidates-index-js": () => import("./../../../src/pages/compare-candidates/index.js" /* webpackChunkName: "component---src-pages-compare-candidates-index-js" */),
  "component---src-pages-compare-candidates-results-index-js": () => import("./../../../src/pages/compare-candidates/results/index.js" /* webpackChunkName: "component---src-pages-compare-candidates-results-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sponsors-partnerships-index-js": () => import("./../../../src/pages/sponsors-partnerships/index.js" /* webpackChunkName: "component---src-pages-sponsors-partnerships-index-js" */),
  "component---src-pages-vote-index-js": () => import("./../../../src/pages/vote/index.js" /* webpackChunkName: "component---src-pages-vote-index-js" */),
  "component---src-templates-candidate-candidate-js": () => import("./../../../src/templates/Candidate/Candidate.js" /* webpackChunkName: "component---src-templates-candidate-candidate-js" */)
}

